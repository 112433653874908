import { render, staticRenderFns } from "./autosave-cell-user.vue?vue&type=template&id=187ff056&scoped=true"
import script from "./autosave-cell-user.vue?vue&type=script&lang=js"
export * from "./autosave-cell-user.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "187ff056",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src3357764168/src/web/components/loading-spinner.vue').default})
