
import moment from 'moment';
import PercentageChange from '../helpers/percentage-change';
import UserCoverageModal from '../users/user-coverage-modal';
import modals from '../../mixins/modals';
import SearchSelect from '../search-select.vue';
import TableActions from '../table-actions.vue';

export default {
    name: 'PeersList',
    components: {
        TableActions,
        SearchSelect,
        PercentageChange,
        UserCoverageModal,
    },
    mixins: [modals],
    props: {
        company: {
            type: Object,
            required: true,
        },
        peerCompanies: {
            type: Array,
            required: true,
        },
        loadingManualPeers: {
            type: Boolean,
            required: true,
        },
        allowEdit: {
            type: Boolean,
            default: false,
        },
        perspective: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            coverageUserId: null,
            loadingPeersSuggestion: false,
            collapseShow: false,
            collapseHidden: true,
            manualPeerGroupsConfig: {
                columns: [
                    {
                        headline: 'Symbol',
                        sort: true,
                    },
                    {
                        headline: 'Name',
                        sort: true,
                    },
                    {
                        headline: 'HQ',
                        breakpoint: 'xl',
                    },
                    {
                        headline: 'Price',
                        sort: true,
                    },
                    {
                        headline: '% Chg',
                        sort: true,
                        breakpoint: 'xl',
                    },
                    {
                        headline: '52 Weeks % Chg',
                        breakpoint: 'xl',
                    },
                    {
                        headline: 'Mkt Cap',
                        sort: true,
                        breakpoint: 'xl',
                    },
                    {
                        headline: 'Cash',
                        title: 'Cash and short term investments',
                        sort: true,
                        breakpoint: 'xl',
                    },
                    {
                        headline: 'Cash Burn',
                        sort: true,
                    },
                    {
                        headline: 'Runway',
                        breakpoint: 'xl',
                    },
                    {
                        headline: 'Debt',
                        title: 'Total Debt',
                        sort: true,
                        breakpoint: 'xl',
                    },

                    {
                        headline: 'HCW Analysts',
                    },
                ],
                pagination: 10,
            },
            updated: moment().unix(),
            manualPeerGroupForSearch: [],
            suggestionsPeerGroupsConfig: {
                columns: [
                    {
                        headline: 'Symbol',
                        sort: true,
                    },
                    {
                        headline: 'Name',
                        sort: true,
                    },
                    {
                        headline: 'HQ',
                    },
                    {
                        headline: 'Price',
                        sort: true,
                    },
                    {
                        headline: '% Chg',
                        sort: true,
                    },
                    {
                        headline: '52 Weeks % Chg',
                        breakpoint: 'xl',
                    },
                    {
                        headline: 'Mkt Cap',
                        sort: true,
                    },
                    {
                        headline: 'Cash',
                        title: 'Cash and short term investments',
                        sort: true,
                    },
                    {
                        headline: 'Cash Burn',
                        sort: true,
                    },
                    {
                        headline: 'Runway',
                    },
                    {
                        headline: 'Debt',
                        title: 'Total Debt',
                        sort: true,
                    },
                    {
                        headline: 'HCW Analysts',
                    },
                    {
                        headline: 'Actions',
                        hideHeadlineBreakpoint: 'all',
                        align: 'end',
                    },
                ],
                pagination: 10,
            },
            suggestionsPeerGroupCompanies: [],
        };
    },
    computed: {
        rows() {
            return this.peerCompanies.map(this.parsePeerData);
        },
        suggestionsPeerRows() {
            return this.suggestionsPeerGroupCompanies.map(this.parsePeerData);
        },
    },
    watch: {
        peerCompanies: {
            handler() {
                this.manualPeerGroupForSearch = this.peerCompanies.filter((x) => x.id !== this.company.id);
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        if (this.allowEdit) {
            this.loadSuggestionsPeerGroups();
            this.$refs.suggestionCollapse.addEventListener('show.bs.collapse', () => {
                this.collapseShow = true;
                this.collapseHidden = false;
            });
            this.$refs.suggestionCollapse.addEventListener('hide.bs.collapse', () => {
                this.collapseShow = false;
            });
            this.$refs.suggestionCollapse.addEventListener('hidden.bs.collapse', () => {
                this.collapseHidden = true;
            });
        }
    },
    methods: {
        formatPercentage(number) {
            if (number === Infinity || isNaN(number)) {
                return 'NA';
            }
            return number.toFixed(2) + '%';
        },
        formatRunway(runway) {
            if (runway === Infinity || isNaN(runway)) {
                return 'NA';
            }
            return `${runway} months`;
        },
        parsePeerData(item) {
            const stockInfo = item.stockData || {};
            const cash = stockInfo.cashAndShortTermInvestments;
            const debt = stockInfo.debt;

            return {
                classes: item.id === this.company.id ? 'selected-company' : '',
                cells: [
                    {
                        text: item.ticker,
                    },
                    {
                        type: 'name',
                        item,
                        sortValue: item.name,
                    },
                    {
                        text: item.address,
                    },
                    {
                        text: stockInfo ? this.formatCurrency(stockInfo.price, stockInfo.currency) : '',
                        sortValue: stockInfo ? stockInfo.price : 0,
                    },
                    {
                        type: 'percentage-change',
                        change: stockInfo ? stockInfo.changesPercentage : '',
                        sortValue: stockInfo ? stockInfo.changesPercentage : 0,
                    },
                    {
                        text: this.formatPercentage(item.change52WeeksAgo),
                    },
                    {
                        text: stockInfo ? this.nFormatCurrency(stockInfo.currency, stockInfo.marketCap) : '',
                        sortValue: stockInfo ? stockInfo.marketCap : 0,
                    },
                    {
                        html: cash
                            ? this.nFormatCurrency(cash.currency, cash.value) +
                                "<br/><span class='text-muted'>(" +
                                this.formatDate(cash.date) +
                                ')</span>'
                            : '',
                        sortValue: cash,
                    },
                    {
                        text: this.formatNumber(item.cashBurn?.value ?? 0),
                        sortValue: item.cashBurn?.value ?? 0,
                    },
                    {
                        text: this.formatRunway(stockInfo.runway),
                    },
                    {
                        html: debt
                            ? this.nFormatCurrency(debt.currency, debt.value) +
                                "<br/><span class='text-muted'>(" +
                                this.formatDate(debt.date) +
                                ')</span>'
                            : '',
                        sortValue: debt,
                    },

                    {
                        type: 'ratings',
                        ratings: stockInfo ? stockInfo.ratings : [],
                    },
                ],
            };
        },
        formatNumber(num) {
            if (num >= 1e9) {
                return (num / 1e9).toFixed(1) + 'B';
            } else if (num >= 1e6) {
                return (num / 1e6).toFixed(1) + 'M';
            } else if (num >= 1e3) {
                return (num / 1e3).toFixed(1) + 'K';
            } else {
                return num.toString();
            }
        },
        updatePeerGroup() {
            const data = {
                peerGroup: this.manualPeerGroupForSearch,
                perspective: this.perspective,
            };
            this.$emit('update-peer-group', data);
        },
        addToManualPeerGroup(id) {
            this.manualPeerGroupForSearch.push({ id });
            const data = {
                peerGroup: this.manualPeerGroupForSearch,
                perspective: this.perspective,
            };
            this.$emit('update-peer-group', data);
            setTimeout(() => {
                this.loadSuggestionsPeerGroups();
            }, 1000);
        },
        loadSuggestionsPeerGroups() {
            this.loadingPeersSuggestion = true;
            this.$axios
                .get('/api/companies/peer_group_suggestion/' + this.company.id)
                .then((response) => {
                    this.suggestionsPeerGroupCompanies = response.data;
                    this.updated = moment().unix();
                })
                .finally(() => {
                    this.loadingPeersSuggestion = false;
                });
        },
        openCoverageModal(userId) {
            this.coverageUserId = userId;
            this.$nextTick(() => {
                this.coverageModal = this.openModal(this.$refs.userCoverageModal);
            });
        },
    },
};
