
import moment from "moment/moment";
import flatPickr from 'vue-flatpickr-component';
import modals from "../../mixins/modals";
import TableFilters from "../table-filters";
import QuintableSearchInput from "../helpers/quintable-search-input.vue";
import ExpandableText from "../helpers/expandable-text.vue";
import HintText from "../hint-text.vue";
import fileDownload from "../../mixins/file-download";
import TableActions from "../table-actions.vue";
import ResearchReportReadershipModal from "../research-reports/research-report-readership-modal.vue";
import ResearchReportBulkOperationModal from "./research-report-bulk-operation-modal";
import 'flatpickr/dist/flatpickr.css';

export default {
    name: "ResearchReportsTable",
    components: {
        ResearchReportReadershipModal,
        TableActions,
        HintText,
        ExpandableText,
        QuintableSearchInput,
        TableFilters,
        ResearchReportBulkOperationModal,
        flatPickr
    },
    mixins: [modals, fileDownload],
    props: {
        narrow: {
            type: Boolean,
            default: false
        },
        userId: {
            type: Number,
            default: null
        },
        enableSelect: {
            type: Boolean,
            default: false
        },
        companiesHash: {
            type: String,
            default: null,
        },
        companyId: {
            type: Number,
            default: null,
        },
        showExcelDownload: {
            type: Boolean,
            default: false,
        },
        pageSize: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            config: {
                columns: [
                    {
                        headline: "Date",
                        sort: true,
                    },
                    {
                        headline: "Universe",
                        breakpoint: this.companyId > 0 ? "all" : "lg",
                    },
                    {
                        headline: "Company",
                        sort: true,
                        breakpoint: "md",
                    },
                    {
                        headline: "Ticker",
                        sort: true,
                        breakpoint: this.companyId > 0 ? "all" : "",
                    },
                    {
                        headline: "Title",
                        sort: false,
                        sticky: this.narrow,
                        hideHeadlineBreakpoint: this.narrow ? "all" : "",
                        breakpoint: this.narrow ? '' : 'lg',
                    },
                    {
                        headline: "Analysts",
                        sort: true,
                        breakpoint: "lg",
                    },
                    {
                        headline: "Document Type",
                        sort: true,
                        breakpoint: this.narrow ? 'all' : 'xl',
                    },
                    {
                        headline: "Price Target",
                        sort: true,
                        breakpoint: this.narrow ? 'all' : '',
                        align: "end",
                    },
                    {
                        headline: "Rating",
                        sort: true,
                        breakpoint: this.narrow ? 'all' : '',
                        align: "end",
                    },
                    {
                        headline: "Cash",
                        sort: true,
                        breakpoint: this.narrow ? 'all' : 'lg',
                        align: "end",
                    },
                    {
                        headline: "Related Companies",
                        breakpoint: this.narrow ? 'all' : 'xl',
                        classes: "max-width-300",
                    },
                    {
                        headline: "Initiating",
                        sort: true,
                        breakpoint: this.narrow ? 'all' : 'xxl',
                    },
                    {
                        headline: "Dropping",
                        sort: true,
                        breakpoint: this.narrow ? 'all' : 'xxl',
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                    }
                ],
                selectPosition: "pre",
                search: true,
                pagination: this.pageSize ?? (this.narrow ? 5 : 50),
                ajaxUrl: '/api/research_reports/list',
                select: this.enableSelect,
                prettySelect: this.enableSelect,
            },
            sortOrder: [{
                headline: "Date",
                index: 0,
                asc: false,
            }],
            tableUpdated: this.generateUUID(),
            selectedType: [],
            selectedCompanies: [],
            selectedUsers: [],
            onlyOwnCompanies: false,
            selectedRows: [],
            preSelectedRows: [],
            preSelectedRowIds: [],
            ajaxRows: [],
            pageChanged: false,
            ajaxAll: 0,
            tableLoaded: false,
            bulkTriggered: false,
            bulkUpdated: moment().unix(),
            bulkModal: null,
            mode: "filtered",
            filterOptions: [],
            dateConfig: {
                mode: 'range',
                altInput: true,
                altFormat: "m/d/Y",
                dateFormat: 'Y-m-d',
            },
            sinceMorningSummary: false,
            dateRange: null,
            universes: [],
            reportIdForReadership: null,
            reportTitleForReadership: null,
            modal: null,
        };
    },
    computed: {
        filters() {

            let from = null;
            let to = null;
            if (this.dateRange) {
                [from, to = from] = this.dateRange.split(" to ");
            }

            return {
                type: this.selectedType,
                companies: this.companyId ? [this.companyId] : this.selectedCompanies.map(company => company.id),
                companiesHash: this.companiesHash,
                userId: (this.onlyOwnCompanies && this.userInfo) ? this.userInfo.id : null,
                from,
                to,
                relevantForUserId: this.userId,
                authors: this.selectedUsers.map(user => user.id),
                sinceMorningSummary: this.sinceMorningSummary,
                universes: this.universes,
            };
        },
        bulkFilters() {
            switch (this.mode) {
                case "selected":
                    return {
                        ids: structuredClone(this.preSelectedRowIds),
                    }
                case "page":
                    return {
                        ids: this.ajaxRows.map(function (row) {
                            return row.research_report_id;
                        })
                    }
                case "filtered":
                default:
                    if (this.searchQuery) {
                        return {...this.filters, 'search_term': this.searchQuery}
                    }
                    return this.filters;
            }
        },
    },
    watch: {
        filters: {
            handler() {
                this.pageChanged = true;
            },
            deep: true,
        },
        selectedRows(rows) {
            if (!this.pageChanged && this.ajaxRows) {
                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedRowIds.includes(rows[i].research_report_id)) {
                        this.preSelectedRowIds.push(rows[i].research_report_id);
                    }
                }

                for (let j = 0; j < this.ajaxRows.length; j++) {
                    const id = this.ajaxRows[j].research_report_id;

                    const index = this.preSelectedRowIds.indexOf(id);

                    if (!rows.map(r => r.research_report_id).includes(id) && index !== -1) {
                        this.preSelectedRowIds.splice(index, 1);
                    }
                }
            }
        }
    },
    mounted() {
        this.loadFilterOptions();
    },

    methods: {
        loadFilterOptions() {
            this.$axios.get("/api/research_reports/filter_options")
                .then((response) => {
                    this.filterOptions = response.data;
                });
        },
        resetFilters() {
            this.selectedType = [];
            this.selectedCompanies = [];
            this.selectedUsers = [];
            this.onlyOwnCompanies = false;
            this.dateRange = null;
            this.sinceMorningSummary = false;
            this.universes = [];
        },
        onRowsUpdated(data) {
            this.pageChanged = false;
            if (data && data.rows && data.rows.length) {
                this.preSelectedRows = this.preSelectedRowIds.map(id => {
                    return {
                        key: "research_report_id",
                        value: id
                    }
                });
            }

            this.ajaxRows = data.rows;
            this.ajaxAll = data.all;
            this.tableLoaded = true;
            // if (this.userInfo && ((this.userId === this.userInfo.id) || (this.relevantForUserId === this.userInfo.id))) {
            //     this.$emit('company-list-loaded', data);
            // }
        },
        onPageChange() {
            this.pageChanged = true;
        },
        clearPreSelection() {
            this.preSelectedRowIds = [];
            this.preSelectedRows = [];
        },
        openBulkOperationModal(mode) {
            this.mode = mode;
            this.bulkUpdated = moment().unix();
            this.bulkTriggered = true;

            this.$nextTick(() => {
                this.bulkModal = this.openModal(this.$refs.researchReportBulkModal)
            })
        },
        onBulkSubmitted(doReload = false) {
            if (doReload) {
                this.tableUpdated = this.generateUUID();
            }
            this.closeModal(this.bulkModal);
        },
        formatAdditionalInfoForFile(humanFileSize, numberPages) {
            const additionalInfo = [];
            if (humanFileSize) {
                additionalInfo.push(humanFileSize);
            }
            if (numberPages) {
                additionalInfo.push(`${numberPages} pages`)
            }
            return additionalInfo.join(', ');
        },
        downloadExcel() {
            const query = {
                search: this.searchQuery,
                filters: this.filters,
                sort: this.sortOrder,
            }

            this.saveQueryAndExport(query, '/api/research_reports/excel');
        },
        onSinceMorningSummaryChange(newValue) {
            // if the new value is true, make sure that "from" is set.
            if (newValue && (!this.dateRange || !this.dateRange.includes(" to "))) {
                this.setDateRange("SLWD");
            }
        },
        getLastWeekdayDate() {
            const date = new Date();
            const today = date.getDay();
            let lastWeekday;

            switch (today) {
                case 0: // Sunday
                    lastWeekday = new Date(date.setDate(date.getDate() - 2));
                    break;
                case 1: // Monday
                    lastWeekday = new Date(date.setDate(date.getDate() - 3));
                    break;
                default: // Tuesday to Saturday
                    lastWeekday = new Date(date.setDate(date.getDate() - 1));
                    break;
            }

            console.log("lastWeekday", lastWeekday);

            return lastWeekday;
        },
        setDateRange(range) {
            this.sinceMorningSummary = false;
            switch (range) {
                case "SLWD":
                    this.dateRange = this.dateToString(this.getLastWeekdayDate()) + " to " + this.dateToString(new Date());
                    this.sinceMorningSummary = true;
                    break;
                case "1D":
                    this.dateRange = this.dateToString(new Date());
                    break;
                case "1W":
                    // eslint-disable-next-line no-case-declarations
                    const prevWeek = new Date()
                    prevWeek.setDate(new Date().getDate() - 7)
                    this.dateRange = this.dateToString(prevWeek) + " to " + this.dateToString(new Date());
                    break;
                case "1M":
                    // eslint-disable-next-line no-case-declarations
                    const prevMonth = new Date()
                    prevMonth.setMonth(new Date().getMonth() - 1)
                    this.dateRange = this.dateToString(prevMonth) + " to " + this.dateToString(new Date());
                    break;
                default:
                    console.log("Unknown date range: " + range);
            }
        },
        dateToString(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to get the correct month since getMonth() returns 0-based index
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        async showReadership(id, title) {
            this.reportIdForReadership = id;
            this.reportTitleForReadership = title;
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.researchReportReadershipModal);
        },
        onReadershipClosed() {
            this.reportIdForReadership = null;
            this.reportTitleForReadership = null;
            this.closeModal(this.modal);
            this.modal = null;
        }
    }
}
