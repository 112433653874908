import { render, staticRenderFns } from "./autosave-cell-text-suggest.vue?vue&type=template&id=6b508128&scoped=true"
import script from "./autosave-cell-text-suggest.vue?vue&type=script&lang=js"
export * from "./autosave-cell-text-suggest.vue?vue&type=script&lang=js"
import style0 from "./autosave-cell-text-suggest.vue?vue&type=style&index=0&id=6b508128&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b508128",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src3357764168/src/web/components/loading-spinner.vue').default})
